<template>
    <el-dialog :visible.sync="visible" width="900px">
        <div class="perform-center2">
            <div class="top">
                <div class="title">{{ mouldTit }}</div>
                <el-input v-model="illustrate" type="textarea" :rows="5" placeholder="请输入考核说明介绍"></el-input>
            </div>
            <el-divider></el-divider>
            <div class="box">
                <div class="item" v-for="(item, index) in list" :key="index">
                    <div class="list">
                        <div class="titBox">
                            <div class="tit">{{ item.Parent_Title }}</div>
                            <div class="set">
                                <span>[分值：{{ item.Parent_Score_Max }}]</span>
                            </div>
                        </div>
                        <div class="score" v-if="item.Is_SetScore">
                            <span>评分</span>
                            <el-input style="width: 30%;" readonly></el-input>
                        </div>
                    </div>
                    <div class="child">
                        <div class="childItem" v-for="(child, ind) in item.Children" :key="ind">
                            <div class="childTit">
                                <div class="tit"> {{ child.Child_Title }}</div>
                                <div class="set">
                                    <span>[分值：{{ child.Child_Score_Max }}]</span>
                                </div>
                            </div>
                            <div class="score">
                                <span>评分</span>
                                <el-input style="width: 30%;" readonly></el-input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </el-dialog>
</template>
<script>
export default {
    data() {
        return {
            visible: false,
            mouldTit: '',
            illustrate: '',
            list: [],
            show: true,
        }
    },
    methods: {
        init(r) {
            this.visible = true
            this.getInfo(r)
        },
        changeChild() {
            this.show = !this.show
        },
        getInfo(r) {
            let self = this
            this.whale.remote.getResult({
                url: "/api/School/PFM/PfmTempalteSetting/Get?Id=" + r.ID,
                completed: function (its) {
                    self.mouldTit = its.DATA.Template_Name
                    self.illustrate = its.DATA.Assessment_Description
                    self.list = its.DATA.Details
                }
            })
        },
    }
}
</script>
<style lang="less" scoped>
.head {
    padding: 20px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.perform-center2 {
    width: 800px;
    height: 600px;
    overflow-y: auto;
    background: #fff;
    margin: 0px auto 0;
    padding: 20px 40px;
}

.perform-center2 .top .title {
    color: #1ea0fa;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
}

.perform-center2 .add {
    text-align: center;
    color: #65adff;
    border: 1px solid #bfdfff;
    background: #f5fcff;
    padding: 10px 0;
    cursor: default;
}

.perform-center2 .box {}

.perform-center2 .box .item .list {
    position: relative;
}

.perform-center2 .box .item .list .cut {
    position: absolute;
    left: -20px;
    top: 20px;
    display: inline-block;
    background: #43aeef;
    width: 10px;
    height: 10px;
}

.perform-center2 .box .titBox {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fafafa;
    color: #111111;
    padding: 10px 10px;
    border: 1px solid #ebeef5;
    font-weight: bold;
    font-size: 15px;
}

.perform-center2 .box .item .list .score {
    border: 1px solid #f0f0f0;
    padding: 10px 10px;
    width: 90%;
    margin: 10px auto;
}

.perform-center2 .box .titBox .set {
    color: #81b9fe;
}

.perform-center2 .box .titBox .set span {
    margin: 0 5px;
}

.perform-center2 .box .childAdd {
    width: 90%;
    border: 1px solid #ebeef5;
    text-align: center;
    color: #65adff;
    padding: 10px 0;
    cursor: default;
    margin: 10px auto;
}

.perform-center2 .box .childItem {
    width: 90%;
    margin: 0 auto;
}

.perform-center2 .box .childItem .childTit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    font-size: 14px;
}

.perform-center2 .box .childItem .score {
    border: 1px solid #f0f0f0;
    padding: 10px 10px;
}
</style>